<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row mb-4">
      <div class="col-xl-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center">
              <!-- <b-dropdown
                class="float-end"
                variant="white"
                right
                menu-class="dropdown-menu-end"
                toggle-class="font-size-16 text-body p-0"
              >
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-v"></i>
                </template>
                <a class="dropdown-item" href="#">Edit</a>
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Remove</a>
              </b-dropdown> -->
              <div class="clearfix"></div>
              <div v-show="!isEdit">
                <img
                  :src="data['photo']"
                  class="avatar-lg rounded-circle img-thumbnail"
                />
              </div>
              <div v-show="isEdit">
                <p class="mb-1">Photo (suggested: 300x300):</p>
                <input
                  type="file"
                  class="form-control"
                  @change="fileChange('profil_photo', $event)"
                />
              </div>
              <h5 class="mt-3 mb-1" v-if="!isAccount">
                {{ data["resp_name"] }}
              </h5>
              <p class="text-muted">{{ data["name"] }}</p>
            </div>
            <form>
              <hr class="my-4" />
              <div class="text-muted" v-if="!isAccount">
                <div class="table-responsive mt-4 mb-0">
                  <div>
                    <p class="mb-1">Name :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["resp_name"] }}
                    </h5>
                    <input
                      type="text"
                      v-show="isEdit"
                      class="form-control"
                      :placeholder="data['resp_name']"
                      v-model="formData.resp_name"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Phone :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["resp_phone"] }}
                    </h5>
                    <input
                      type="tel"
                      class="form-control"
                      v-mask="'(###) ###-####'"
                      v-show="isEdit"
                      :placeholder="data['resp_phone']"
                      v-model="formData.resp_phone"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Email :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["resp_email"] }}
                    </h5>
                    <input
                      type="email"
                      class="form-control"
                      v-show="isEdit"
                      :placeholder="data['resp_email']"
                      v-model="formData.resp_email"
                    />
                  </div>
                </div>
              </div>
              <div class="text-muted" v-if="isAccount">
                <div class="table-responsive mt-4 mb-0">
                  <div>
                    <p class="mb-1">Name :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["name"] }}
                    </h5>
                    <input
                      type="text"
                      v-show="isEdit"
                      class="form-control"
                      :placeholder="data['name']"
                      v-model="formData.name_account"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Phone :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["phone"] }}
                    </h5>
                    <input
                      type="tel"
                      class="form-control"
                      v-mask="'(###) ###-####'"
                      v-show="isEdit"
                      :placeholder="data['phone']"
                      v-model="formData.phone_account"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Email :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["email"] }}
                    </h5>
                    <input
                      type="email"
                      class="form-control"
                      v-show="isEdit"
                      :placeholder="data['email']"
                      v-model="formData.email_account"
                    />
                  </div>
                </div>
                <button
                  class="btn btn-warning mt-4"
                  type="button"
                  v-show="!isEdit"
                  @click="isEdit = true"
                >
                  Edit
                </button>
                <button
                  class="btn btn-primary mt-4"
                  type="button"
                  v-show="isEdit"
                  @click="formDataSendAccount()"
                >
                  Save
                </button>
              </div>
              <hr class="my-4" v-if="!isAccount" />
              <div class="text-muted" v-if="!isAccount">
                <div class="table-responsive mt-4 mb-0">
                  <div>
                    <p class="mb-1">Company Name :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["name"] }}
                    </h5>
                    <input
                      type="text"
                      v-show="isEdit"
                      class="form-control"
                      disabled
                      :placeholder="data['name']"
                      v-model="formData.name"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Country :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["country"] }}
                    </h5>
                    <input
                      type="text"
                      v-show="isEdit"
                      class="form-control"
                      :placeholder="data['country']"
                      v-model="formData.country"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Address :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["address"] }}
                    </h5>
                    <input
                      type="text"
                      v-show="isEdit"
                      class="form-control"
                      :placeholder="data['address']"
                      v-model="formData.address"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Phone :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["phone"] }}
                    </h5>
                    <input
                      type="tel"
                      class="form-control"
                      v-mask="'(###) ###-####'"
                      v-show="isEdit"
                      :placeholder="data['phone']"
                      v-model="formData.phone"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Email :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["email"] }}
                    </h5>
                    <input
                      type="email"
                      class="form-control"
                      v-show="isEdit"
                      :placeholder="data['email']"
                      v-model="formData.email"
                    />
                  </div>
                  <div class="mt-4">
                    <p class="mb-1">Website :</p>
                    <h5 class="font-size-16" v-show="!isEdit">
                      {{ data["website"] }}
                    </h5>
                    <input
                      type="text"
                      class="form-control"
                      v-show="isEdit"
                      :placeholder="data['website']"
                      v-model="formData.website"
                    />
                  </div>
                  <div v-show="isEdit">
                    <p class="mb-1">Logo (suggested: 300x300):</p>
                    <input
                      type="file"
                      class="form-control"
                      @change="fileChange('logo', $event)"
                    />
                  </div>
                  <button
                    class="btn btn-warning mt-4"
                    type="button"
                    v-show="!isEdit"
                    @click="isEdit = true"
                  >
                    Edit
                  </button>
                  <button
                    class="btn btn-primary mt-4"
                    type="button"
                    v-show="isEdit"
                    @click="formDataSend()"
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-xl-8">
        <div class="card mb-0">
          <b-tabs content-class="p-4" justified class="nav-tabs-custom">
            <b-tab active>
              <template v-slot:title>
                <i class="uil uil-user-circle font-size-20"></i>
                <span class="d-none d-sm-block">About</span>
              </template>
              <div>
                <div>
                  <h5 class="font-size-16 mb-4">Logs</h5>

                  <ul class="activity-feed mb-0 ps-2">
                    <li class="feed-item">
                      <div class="feed-item-list">
                        <p class="text-muted mb-1">2019 - 2020</p>
                        <h5 class="font-size-16">UI/UX Designer</h5>
                        <p>Abc Company</p>
                      </div>
                    </li>
                  </ul>
                </div>

                <div v-if="!isAccount">
                  <div class="row">
                    <div class="col-lg-6">
                      <h5 class="font-size-16 mb-4">Users</h5>
                    </div>
                    <div class="col-lg-6" style="text-align: end">
                      <button
                        type="button"
                        v-b-modal.modal-center
                        class="btn btn-outline-primary waves-effect waves-light"
                        data-toggle="modal"
                        data-target=".bs-example-modal-center"
                      >
                        New User
                      </button>
                      <b-modal
                        id="modal-center"
                        centered
                        title="Responsible Person"
                        title-class="font-20"
                        hide-footer
                      >
                        <div class="row">
                          <div class="col-12">
                            <input
                              type="text"
                              placeholder="Name :"
                              class="form-control mb-2"
                              v-model="formData.new_resp_name"
                            />
                          </div>
                          <div class="col-12">
                            <input
                              type="tel"
                              placeholder="Phone :"
                              class="form-control mb-2"
                              v-mask="'(###) ###-####'"
                              v-model="formData.new_resp_phone"
                            />
                          </div>
                          <div class="col-12">
                            <input
                              type="email"
                              placeholder="Email :"
                              class="form-control mb-2"
                              v-model="formData.new_resp_email"
                            />
                          </div>
                          <div class="col-12">
                            <input
                              type="password"
                              placeholder="Password :"
                              class="form-control mb-2"
                              v-model="formData.new_resp_password"
                            />
                          </div>

                          <div class="col-12">
                            <button
                              class="btn btn-primary btn-block"
                              @click="formDataSendNewUser()"
                            >
                              Save
                            </button>
                          </div>
                        </div>
                      </b-modal>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table class="table table-nowrap table-hover mb-0">
                      <thead>
                        <tr>
                          <th scope="col">#</th>
                          <th scope="col">Name</th>
                          <th scope="col">Phone</th>
                          <th scope="col">Email</th>
                          <th scope="col">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(entry, key) in data['company_user_account']"
                          :key="key"
                        >
                          <th scope="row">{{ entry.id }}</th>
                          <td>
                            <a href="#" class="text-dark">{{ entry.name }}</a>
                          </td>
                          <td>{{ entry.phone }}</td>
                          <td>{{ entry.email }}</td>
                          <td>
                            <b-button-group>
                              <b-button variant="outline-primary">
                                <i class="fas fa-pencil-alt"></i>
                              </b-button>

                              <b-button
                                variant="outline-danger"
                                @click="deleteLpPopup(entry.id)"
                              >
                                <i class="fas fa-times-circle"></i
                              ></b-button>
                            </b-button-group>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-tab>

            <b-tab v-if="!isAccount">
              <template v-slot:title>
                <i class="uil uil-clipboard-notes font-size-20"></i>
                <span class="d-none d-sm-block">Agreement</span>
              </template>
              <div>
                <h3 style="border-bottom: 1px solid #ddd; width: 140px">
                  Agreement
                </h3>
                <p></p>
                <p>
                  Lead Comissiom Rate
                  <b style="border-bottom: 1px solid #ddd">
                    %{{ data["company_contract"]["lead_comission"] }}
                  </b>
                </p>
                <p>
                  Deal Comissiom Rate
                  <b style="border-bottom: 1px solid #ddd">
                    %{{ data["company_contract"]["deal_comission"] }}
                  </b>
                </p>

                <h3 style="border-bottom: 1px solid #ddd; width: 190px">
                  <b> What is Lead ?</b>
                </h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Accusantium incidunt nihil et labore eum obcaecati, error
                  minima ducimus veritatis consequatur, minus repellat. Rem
                  repellat ipsum architecto quos, quasi maxime sed!
                </p>
                <h3 style="border-bottom: 1px solid #ddd; width: 190px">
                  <b> What is Deal ?</b>
                </h3>
                <p>
                  Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  Accusantium incidunt nihil et labore eum obcaecati, error
                  minima ducimus veritatis consequatur, minus repellat. Rem
                  repellat ipsum architecto quos, quasi maxime sed!
                </p>
              </div>
            </b-tab>
          </b-tabs>
          <!-- Nav tabs -->
          <!-- Tab content -->
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { API } from "@/state/API";

import { POPUP } from "@/state/popup";

import Swal from "sweetalert2";
export default {
  components: { Layout, PageHeader },
  page: {
    title: "Profile",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      title: "Profile",
      items: [
        {
          text: "Profile",
          active: true,
        },
      ],
      isEdit: false,
      data: [],
      formData: {},
      isAccount: false,
    };
  },
  mounted() {
    this.userCheckController();
  },
  methods: {
    async userCheckController() {
      var token = localStorage.getItem("token");
      await API.Get(API.Auth.userController + token).then((res) => {
        console.log(res);
        var data = res.data.data;
        if (data.is_admin == 0 || localStorage.getItem("company") == 1) {
          this.ListLp();
        } else {
          this.$router.push("/");
          this.statusRefresh = 0;
          var timer = 5000;
          data = "You don't have access permission";
          POPUP.popupClickNot(data, timer);
        }
      });
    },
    async ListLp() {
      this.statusRefresh = 0;
      await API.Get(API.Profile.Index).then((response) => {
        console.log(response);
        this.isAccount = response.data.account;
        this.data = response.data.data;
        console.log(response);
        if (!this.isAccount) {
          this.formData.resp_name = response.data.data["resp_name"];
          this.formData.resp_phone = response.data.data["resp_phone"];
          this.formData.resp_email = response.data.data["resp_email"];
          this.formData.name = response.data.data["name"];
          this.formData.country = response.data.data["country"];
          this.formData.address = response.data.data["address"];
          this.formData.phone = response.data.data["phone"];
          this.formData.email = response.data.data["email"];
          this.formData.website = response.data.data["website"];
        } else {
          this.formData.resp_name = response.data.data["name"];
          this.formData.resp_phone = response.data.data["phone"];
          this.formData.resp_email = response.data.data["email"];
          this.formData.name = null;
          this.formData.country = null;
          this.formData.address = null;
          this.formData.phone = null;
          this.formData.email = null;
          this.formData.website = null;
        }
        var data;
        if (response.data.status === "success") {
          data = "Your work has been refresh";
          POPUP.popupClick(data);
          this.statusRefresh = 1;
        } else {
          data = "Your work could not be renewed";
          POPUP.popupClickNot(data);
          this.statusRefresh = 1;

          this.userCheckController();
        }
      });
    },
    convertToFormData() {
      const _formData = new FormData();

      Object.keys(this.formData).map((key) => {
        _formData.append(key, this.formData[key]);
      });

      return _formData;
    },
    async formDataSend() {
      this.isEdit = false;
      var data;
      const response = await API.Post(
        API.Profile.Update,
        this.convertToFormData()
      );

      if (response.data.req) {
        this.validation(response);
        data = "Fill in the required fields";
        POPUP.popupClickNot(data);
      } else if (response.data.status === "success") {
        this.userCheckController();
      }
    },

    fileChange(fieldName, $event) {
      if ($event.target.files && $event.target.files.length) {
        this.formData[fieldName] = $event.target.files[0];
      }
    },
    async formDataSendAccount() {
      this.isEdit = false;
      var data;
      const response = await API.Post(
        API.Profile.Update + "?account=1",
        this.convertToFormData()
      );

      if (response.data.req) {
        this.validation(response);
        data = "Fill in the required fields";
        POPUP.popupClickNot(data);
      } else if (response.data.status === "success") {
        this.userCheckController();
      }
    },
    async formDataSendNewUser() {
      this.isEdit = false;
      var data;
      const response = await API.Post(API.Profile.CreateUser, {
        new_resp_name: this.formData.new_resp_name,
        new_resp_phone: this.formData.new_resp_phone,
        new_resp_email: this.formData.new_resp_email,
        new_resp_password: this.formData.new_resp_password,
      });
      if (response.data.req) {
        data = "You can create up to 3 users.";
        POPUP.popupClickNot(data);
      } else if (response.data.status === "success") {
        this.formData.new_resp_name = null;
        this.formData.new_resp_phone = null;
        this.formData.new_resp_email = null;
        this.formData.new_resp_password = null;
        this.userCheckController();
      }
    },
    deleteLpPopup(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.value) {
          this.deleteLp(id);
          Swal.fire("Deleted!", "Your file has been deleted.", "success");

          this.userCheckController();
        }
      });
    },
    async deleteLp(id) {
      await API.Get(
        API.Profile.Delete + id + "/" + localStorage.getItem("token")
      ).then((response) => {
        console.log(response.data.status);
      });
    },
  },
};
</script>
